import { Button } from 'primereact/button'
import React, { forwardRef } from 'react'
import { useMutation } from 'react-query'
import configVariablesService from '../../services/configVariables';
import { DeleteIcon } from '../../utils/icons';


const Delete = forwardRef(({ rowData, refreshTable, check }, ref) => {
    const toast = ref ?? null
    const { mutate: onDelete, isLoading } = useMutation(async (id) => {
        const response = await configVariablesService.configVariablesDelete(id)
        return response;
    }, {
        onError: (error) => {
            if (error.response.status === 500 && toast) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Internal Server Error!' });
                return
            }
            toast && toast.current.show({ severity: 'error', summary: 'Error', detail: error?.response?.data?.message ?? 'Opps! Something went wrong' });


        },
        onSuccess: async (res) => {
            refreshTable()
            toast && toast.current.show({ severity: 'success', summary: 'Alert!', detail: res?.message });
        },
    });
    const { CAN_DELETE } = rowData
    return (
        <Button disabled={!CAN_DELETE} icon={<DeleteIcon size={20} />} size='small' rounded link loading={isLoading} style={{ backgroundColor: 'unset !important' }}
             severity='danger' className='action-button text-red-500' onClick={CAN_DELETE ? () => { onDelete(rowData?.id) } : null}></Button>
    )
})

export default Delete