import React, { useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import LongStringWithSeeMore from '../../utils/LongStringWithSeeMore';
import moment from 'moment';
import { useQuery } from 'react-query';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import FormModal from './FormModal';
import Delete from './Delete';
import { Toast } from 'primereact/toast';
import testConfigurationService from '../../services/testConfiguration';
import VariablesValuesList from '../ConfigVariables/VariablesValuesList.js';
import NoDataFound from '../../components/common/NoDataFound/index.js';
import PermissionButtonGroup from '../../components/ActionsButtons.js';
import { Components } from '../../utils/permissions.js';
import usePermission from '../../utils/usePermissionsHook.js';
const TestConfiguration = ({ hide, testCaseId, selectionEnable }) => {
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [updateConfigVariables, setConfigVariables] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const {create: CAN_CREATE, delete: CAN_DELETE, anyPermission} = usePermission(Components.TEST_CONFIGURATION);
  const [pagination, setPagination] = useState({
    first: 0,
    page: 0,
    limit: 10,
    total: 0,
  });
  const columns = [
    { field: 'name', header: 'Name' },
    ...(!hide
      ? [
          { field: data => <LongStringWithSeeMore maxLength={50} content={data.description} />, header: 'Description' },
          { field: data => <VariablesValuesList values={data?.configuration_variables} optionLabel='variable.name' />, header: 'Variables' },
          { field: data => moment(data.created_at ?? '').format('DD-MM-YYYY HH:MM A'), header: 'Created At' },
        ]
      : []),
  ];

  const {
    isLoading,
    data,
    refetch: refreshTable,
    isRefetching,
  } = useQuery({
    queryKey: ['configVariables', { pagination }],
    queryFn: async () => {
      const params = {
        page: pagination.page + 1,
        limit: pagination.limit,
      };
      const response = await testConfigurationService.getTestConfigurations(params);
      const { data } = response;
      const { total } = data.meta;
      setPagination(pg => ({ ...pg, total }));
      return data.data;
    },
    keepPreviousData: true,
  });

  const actionBody = row => {
    return (
      <span className='flex align-items-center'>
        <PermissionButtonGroup
          component={Components.TEST_CONFIGURATION}
          actions={[
            {
              action: 'update',
              onClick: () => {
                setConfigVariables(prev => (prev = row));
                setVisible(true);
              },
            },
          ]}
        />
       {CAN_DELETE && <Delete rowData={row} ref={toast} refreshTable={refreshTable} />}
      </span>
    );
  };

  const modalProps = {
    visible,
    setVisible,
    refreshTable,
    valuesForUpdate: updateConfigVariables,
    resetState: setConfigVariables,
  };

  return (
    <div className={hide ? 'shadow-none' : ''}>
      <Toast ref={toast} />
      <FormModal {...modalProps} ref={toast} />
      <DataTable
        selectionMode={selectionEnable ? 'checkbox' : null}
        selection={selectedRow}
        onSelectionChange={e => setSelectedRow(e.value)}
        header={
          <>
            {testCaseId ? (
              <div className='flex align-items-center justify-content-between'>
                <p>Test Case ID: {testCaseId}</p>
                <Button label='Assign' icon='pi pi-check' disabled iconPos='right' size='small' raised className='surface-300 border-none text-color' />
              </div>
            ) : (
                CAN_CREATE && <Button
                icon='pi pi-plus'
                size='small'
                label='Add'
                text
                raised
                onClick={() => {
                  setVisible(true);
                }}
              ></Button>
            )}
          </>
        }
        value={data ?? []}
        lazy
        first={pagination.first}
        totalRecords={pagination.total}
        paginator
        rows={pagination.limit}
        onPage={event => {
          setPagination(pg => {
            return { ...pg, page: event.page, first: event.first };
          });
        }}
        dataKey='id'
        loading={isLoading | isRefetching}
        className='datatable-responsive w-full'
        emptyMessage={<NoDataFound message={'No configurations are currently available.'} />}
        filterDisplay='row'
        responsiveLayout='scroll'
      >
        {selectionEnable && <Column selectionMode='multiple' headerStyle={{ width: '3rem' }}></Column>}
        {columns.map((col, key) => (
          <Column {...col} key={key} />
        ))}
        {!hide && anyPermission && <Column body={actionBody} header='Actions'></Column>}
      </DataTable>
    </div>
  );
};

export default TestConfiguration;
