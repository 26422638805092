 import api from "../utils/api";

class TicketSystemService {

    /**
     * 
     * @param {object} data Object of user data 
     * @returns 
     */
    createTicketSystem(data) {
        const config = { url: '/ticketing-system', method: 'post', data }
        return api.request(config)
    }

    /**
   * 
   * @param {string} id id of ticket system
   * @param {AbortController} abortController to abort request on unmount
   * @returns 
   */
    getTicketSystem(id, abortController) {

        const config = { url: `/ticketing-system/${id}`, method: 'get', ...(abortController && { signal: abortController.signal }) }
        return api.request(config)
    }

        /**
   * 
   * @param {string} id id of ticket system
   * @param {AbortController} abortController to abort request on unmount
   * @returns 
   */
        updateTicketSystem(id, data, abortController) {

            const config = { url: `/ticketing-system/${id}`, method: 'put', data, ...(abortController && { signal: abortController.signal }) }
            return api.request(config)
        }
    
    /**
     * 
     * @param {AbortController} abortController to abort request on unmount
     * @returns 
     */
    getTicketSystems(abortController,params=null) {
        const config = {
            url: '/ticketing-system',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && {params:params})
        }
        return api.request(config)
    }

    getTicketSystemTypes(abortController,params=null){
        const config = {
            url: '/ticketing-system-type',
            method: 'get',
            ...(abortController && { signal: abortController.signal }),
            ...(params && {params:params})
        }
        return api.request(config)
    }
 
    /**
         * 
         * @param {object} data Object of  data 
         * @returns 
         */
    saveTestInTicketSysten(data) {
        const config = { url: '/save/testsuites/ticketing-system', method: 'post', data }
        return api.request(config)
    }

    uploadStepInTicketSystem(data){
        const config = { url: '/upload/testsuites/steps/ticketing-system', method: 'post', data }
        return api.request(config)
    }
     
    /**
     * 
     * @param {array<string>} ids ids of projects
     * @returns 
     */
    deleteTickeSystems(ids){
        const config={ url: `/ticketing-system/bulk-delete`, method: 'post', data: { ticketing_ids: ids } }
        return api.request(config)
    }
    
}

const ticketSystemService = new TicketSystemService()
export default ticketSystemService