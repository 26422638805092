// path: components/PermissionButtonGroup.js

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import usePermission from '../utils/usePermissionsHook';
import { DeleteIcon, EditIcon, EyeIcon, PlusIcon } from '../utils/icons';

// Button component for individual actions
const ActionButton = React.forwardRef(({ label, onClick, to, disabled, className, style, icon: iconProp, action, iconSize , ...rest  }, ref) => {
  const icon =
    iconProp ||
    {
      delete: <DeleteIcon size={iconSize || 20} />,
      update: <EditIcon size={iconSize || 20} />,
      view: <EyeIcon size={iconSize || 20} />,
      create: <PlusIcon size={iconSize || 25} />,
    }[action];
    const trashIconColor = action === 'delete' ? 'text-red-500' : '';
  return to ? (
    <Link to={to} disabled={disabled} ref={ref}>
      <Button  icon={icon} onClick={onClick} disabled={disabled} className={`p-button-icon p-button-sm p-1 p-button-link  ${className} ${trashIconColor}`} style={{ minWidth: 'unset !important', backgroundColor: 'unset !important', ...style }} label={label} rounded {...rest}>
       
      </Button>
    </Link>
  ) : (
    <Button onClick={onClick} icon={icon} disabled={disabled} ref={ref} className={`p-button-sm flex-none p-button-icon p-1 p-button-link ${className} ${trashIconColor}`} style={{ backgroundColor: 'unset !important', minWidth: 'unset !important', ...style }} rounded label={label} {...rest}>
   
    </Button>
  );
});

// PermissionButtonGroup component
const PermissionButtonGroup = React.forwardRef(({
  component, // Component name to check permissions for
  actions,
  wrapperClass,
  ...props // Array of action configurations
}, ref) => {
  const permissions = usePermission(component);

  return (
    <div className={`${wrapperClass} flex  `}>
      {actions.map(({ action, label, onClick, to, className, style, icon, disabled, iconSize , ref , ...props }) => {
        // Only render the button if the user has the required permission
        if (action !== 'view' && !permissions[action]) return null;

        return (
          <ActionButton
            key={action}
            action={action}
            label={label}
            onClick={onClick}
            to={to}
            className={className}
            style={style}
            disabled={disabled}
            icon={icon}
            iconSize={iconSize} // Optional icon component for the button
            ref={ref}
            {...props}
          />
        );
      })}
    </div>
  );
});

PermissionButtonGroup.propTypes = {
  component: PropTypes.string.isRequired,
  wrapperClass: PropTypes.string, // Name of the component to check permissions
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.oneOf(['create', 'view', 'update', 'delete']).isRequired, // The action to check permissions for
      label: PropTypes.string || null, // Button label
      onClick: PropTypes.func || null, // Callback function for button click
      to: PropTypes.string, // URL for anchor link (optional)
      className: PropTypes.string, // Additional CSS class
      style: PropTypes.object, // Inline styles
      icon: PropTypes.element, //
      iconSize: PropTypes.number, // Icon size
    }),
  ).isRequired,
};

export default PermissionButtonGroup;
