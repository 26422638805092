// path: hooks/usePermission.js

import { useEffect, useState } from "react";
import secureLocalStorage from "./secureLocalStorage";
import { ActionKeywords } from "./permissions";

const usePermission = (component) => {
    const [permissions, setPermissions] = useState({
        create: false,
        update: false,
        delete: false,
        anyPermission: false,
    });

    useEffect(() => {
        // Retrieve and process permissions once
        const currentUser = secureLocalStorage.getItem('currentUser');
        const userPermissions = currentUser?.user_permissions?.flatMap(perm => perm.permissions) || [];

        // Calculate permissions for the given component
        const updatedPermissions = Object.fromEntries(
            Object.entries(ActionKeywords).map(([action, keyword]) => [
                action,
                userPermissions.includes(`${keyword} ${component}`),
            ])
        );
        updatedPermissions.anyPermission = Object.values(updatedPermissions).some(Boolean);

        setPermissions(updatedPermissions);
    }, [component]); // Dependency array ensures the effect only runs when the component changes

    return permissions;
};

export default usePermission;
