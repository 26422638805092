import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import LongStringWithSeeMore from '../../utils/LongStringWithSeeMore';
import moment from 'moment';
import { useQuery } from 'react-query';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import FormModal from './FormModal';
import configVariablesService from '../../services/configVariables';
import Delete from './Delete';
import { Toast } from 'primereact/toast';
import VariablesValuesList from './VariablesValuesList';
import NoDataFound from '../../components/common/NoDataFound';
import PermissionButtonGroup from '../../components/ActionsButtons';
import { Components } from '../../utils/permissions';
import usePermission from '../../utils/usePermissionsHook';
const ConfigVariables = () => {
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [updateConfigVariables, setConfigVariables] = useState(null);
  const { create: CAN_CREATE, delete: CAN_DELETE, anyPermission } = usePermission(Components.CONFIG_VARIABLE);
  const [pagination, setPagination] = useState({
    first: 0,
    page: 0,
    limit: 10,
    total: 0,
  });
  const columns = [
    { field: 'name', header: 'Name' },
    { field: data => <LongStringWithSeeMore maxLength={50} content={data.description} />, header: 'Description' },
    { field: data => <VariablesValuesList values={data?.variable_values} />, header: 'Variables' },
    { field: data => moment(data.created_at ?? '').format('DD-MM-YYYY HH:MM A'), header: 'Created At' },
  ];

  const {
    isLoading,
    data,
    refetch: refreshTable,
    isRefetching,
  } = useQuery({
    queryKey: ['configVariables', { pagination }],
    queryFn: async () => {
      const params = {
        page: pagination.page + 1,
        limit: pagination.limit,
      };
      const response = await configVariablesService.getConfigVariables(params);
      const { data } = response;
      const { total } = data.meta;
      setPagination(pg => ({ ...pg, total }));
      return data.data;
    },
    enabled: true,
    keepPreviousData: true,
  });

  const actionBody = row => {
    const { CAN_EDIT } = row;
    return (
      <span className=' flex align-items-center'>
        <PermissionButtonGroup
          component={Components.CONFIG_VARIABLE}
          actions={[
            {
              action: 'update',
              disabled: !CAN_EDIT,
              onClick: CAN_EDIT
                ? () => {
                    setConfigVariables(prev => (prev = row));
                    setVisible(true);
                  }
                : null,
            },
          ]}
        />
        {CAN_DELETE && <Delete rowData={row} ref={toast} refreshTable={refreshTable} />}
      </span>
    );
  };

  const modalProps = {
    visible,
    setVisible,
    refreshTable,
    valuesForUpdate: updateConfigVariables,
    resetState: setConfigVariables,
  };

  return (
    <div>
      <Toast ref={toast} />
      <FormModal {...modalProps} ref={toast} />
      <DataTable
        header={
          CAN_CREATE && (
            <Button
              icon='pi pi-plus'
              size='small'
              label='Add'
              raised
              text
              onClick={() => {
                setVisible(true);
              }}
            ></Button>
          )
        }
        value={data ?? []}
        lazy
        first={pagination.first}
        totalRecords={pagination.total}
        paginator
        rows={pagination.limit}
        onPage={event => {
          setPagination(pg => {
            return { ...pg, page: event.page, first: event.first };
          });
        }}
        dataKey='id'
        loading={isLoading | isRefetching}
        className='datatable-responsive w-full'
        emptyMessage={<NoDataFound message={'No data available for configuration variables'} />}
        filterDisplay='row'
        responsiveLayout='scroll'
      >
        {columns.map((col, key) => (
          <Column {...col} key={key} />
        ))}
        {anyPermission && <Column body={actionBody} header='Actions'></Column>}
      </DataTable>
    </div>
  );
};

export default ConfigVariables;
