import { sortableContainer, sortableElement, arrayMove, sortableHandle } from 'react-sortable-hoc';
import AlertDialog from '../../../../components/common/AlertDialog/AlertDialog';
import Spinner from '../../../../components/common/Spinner/Spinner';
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { testPlanService, userService } from '../../../../services';
import { testPlanActions } from '../../../../store/actions';
import { getBadgeType } from '../../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Toast } from 'primereact/toast';
import { useMutation, useQuery } from 'react-query';
import { Card } from 'primereact/card';
import LongStringWithSeeMore from './../../../../utils/LongStringWithSeeMore';
import AssignConfiguration from './AssignConfiguration';
import VariablesValuesList from '../../../ConfigVariables/VariablesValuesList';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PermissionButtonGroup from '../../../../components/ActionsButtons';
import { Components } from '../../../../utils/permissions';
import { RefreshIcon } from '../../../../utils/icons';
export default React.memo(function TestPlanItemAddedSuitesList(props) {
  const dispatch = useDispatch();

  const { usingIn, selectedRowsCallback, refreshList } = props;
  // const [itemSuitesLoading, setItemSuitesLoading] = useState(true)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [reload, setReload] = useState(false);

  const { details, added_suites } = useSelector(state => state.testPlan);
  const selectedItemIndex = useSelector(state => state.testPlan.activeItemIndex);
  const [expandedRows, setExpandedRows] = useState(null);
  const itemId = details.items[selectedItemIndex].id;

  const toast = useRef();
  const rowsRef = useRef([]);
  const singleDeleteItemId = useRef(null);

  const [usersList, setUsersList] = useState([]);
  const getUsers = useCallback(() => {
    const controller = new AbortController();
    userService
      .getUsers(controller)
      .then(({ data }) => {
        setUsersList(data);
      })
      .catch(err => {
        console.log(err);
      });

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => getUsers(), [getUsers]);

  // const fetchItemSuites = useCallback(() => {
  //     if (selectedItemIndex !== null) {

  //         // setItemSuitesLoading(true)
  //         testPlanService.getTestSuiteItemsFromItem(itemId)
  //             .then(({ data }) => {

  //                 dispatch({ type: testPlanActions.SET_ADDED_SUITES, data: data[0].added_suites })
  //             }).catch((err) => {
  //                 console.log(err)
  //             })
  //         // .finally(() => setItemSuitesLoading(false))
  //     }
  // }, [selectedItemIndex, dispatch, itemId])

  // useEffect(() => fetchItemSuites(), [fetchItemSuites, props.refreshList])
  const {
    data,
    refetch: fetchItemSuites,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ['getSuitsItems', { selectedItemIndex, dispatch, itemId, refreshList }],
    queryFn: async () => await testPlanService.getTestSuiteItemsFromItem(itemId),
    onSuccess: res => {
      const { data } = res;
      dispatch({ type: testPlanActions.SET_ADDED_SUITES, data: data[0].added_suites });
    },
    onError: err => {
      console.log(err);
    },
  });

  const [selectedRows, setSelectedRows] = useState([]);

  const onSelectRow = (checked, id) => {
    if (checked) {
      setSelectedRows(prev => [...prev, id]);
    } else {
      setSelectedRows(prev => {
        prev.splice(prev.indexOf(id), 1);
        return [...prev];
      });
    }
  };

  const selectAll = e => {
    setSelectedRows(e.target.checked ? added_suites.map(suite => (usingIn === 'execute' ? suite.test_design_id : suite.id)) : []);
  };
  useEffect(() => {
    if (selectedRowsCallback) {
      selectedRowsCallback(selectedRows);
    }
  }, [selectedRows, selectedRowsCallback]);

  // if(itemSuitesLoading){
  //     return <Spinner />
  // }
  const DragHandle = sortableHandle(() => <i className='pi pi-align-justify '></i>);

  const SortableContainer = sortableContainer(({ children }) => <tbody className='p-datatable-tbody'>{children}</tbody>);

  const SortableItem = sortableElement(({ children }) => children);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortedTestPlanItems = arrayMove(added_suites, oldIndex, newIndex);
    dispatch({ type: testPlanActions.SET_ADDED_SUITES, data: sortedTestPlanItems });
    testPlanService.sortTestPlanItems(
      null,
      sortedTestPlanItems.map(item => item.id),
    );
  };

  const onDeleteTestPlanItems = () => {
    //api call running.
    console.log(singleDeleteItemId);
    testPlanService
      .deletePlanItem(null, [singleDeleteItemId.current])
      .then(() => {
        toast.current.show({ severity: 'success', summary: 'Deleted', detail: 'Test Plan Item has been deleted successfully' });
        fetchItemSuites();
        onDeleteAlertHide();
      })
      .catch(err => {
        toast.current.show({ severity: 'error', summary: 'Error- Could not delete', detail: 'Something went wrong.' });
      });
  };

  const clearDeletionSeletions = () => {
    if (singleDeleteItemId.current) {
      singleDeleteItemId.current = null;
    }
    if (rowsRef.current.some(el => el.checked)) {
      rowsRef.current.forEach(el => (el.checked = false));
    }
  };

  const onDeleteAlertHide = () => {
    setShowDeleteAlert(false);
    clearDeletionSeletions();
  };
  const mutation = useMutation({
    mutationFn: async suite => {
      const res = await testPlanService.changeTestToNotStarted({ test_plan_item_id: itemId, test_suite_id: suite.id });
      return res;
    },
    onSuccess: data => {
      toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message });
      fetchItemSuites();
    },
    onError: () => {
      toast.current.show({ severity: 'error', summary: 'Error - Could not change the status', detail: 'Something went wrong.' });
    },
  });

  const onStorted = async (values, index) => {
    console.log(values, index);
    const { value, dragIndex, dropIndex } = values;
    const sortedTestPlanItems = value;
    // dispatch({ type: testPlanActions.SET_ADDED_SUITES, data: value })
    await testPlanService.sortTestPlanItems(
      null,
      sortedTestPlanItems.map(item => item.id),
    );
    await fetchItemSuites();
  };

  const columns = [
    {
      header: () => (
        <span className='p-column-title'>
          <input type={'checkbox'} onChange={selectAll} />
        </span>
      ),
      style: { width: '30px', padding: 0 },
      body: rowData => {
        const { id, test_design_id, suite } = rowData;
        const _data = added_suites.filter(suite => suite?.parent_id === rowData.id);
        const configurations = _data ? _data.map(value => value.suite.configuration) : null;
        console.log(configurations);
        return (
          <div className='gap-2 flex align-items-center '>
            {usingIn === 'execute' ? (
              <input
                type={'checkbox'}
                //    ref={(el) => rowsRef.current[i] = el}
                id={id}
                onChange={e => onSelectRow(e.target.checked, test_design_id)}
                checked={selectedRows.includes(test_design_id)}
              />
            ) : (
              <input
                type={'checkbox'}
                //    ref={(el) => rowsRef.current[i] = el}
                id={id}
                onChange={e => onSelectRow(e.target.checked, id)}
                checked={selectedRows.includes(id)}
              />
            )}
            <span>{!suite?.configuration && <AssignConfiguration testCaseId={suite.id} testSuiteId={itemId} assignedConfig={configurations ?? []} refetch={fetchItemSuites} />}</span>
          </div>
        );
      },
    },
    { field: 'suite.id', header: 'ID', style: { padding: 1 } },
    { field: 'suite.title', header: 'Name', style: { minWidth: 220, maxWidth: 230 }, body: ({ suite }) => <LongStringWithSeeMore content={suite?.title} maxLength={35} /> },

    { field: 'suite.state.name', header: 'State', style: { padding: 1 } },
    { field: 'added_suites.users', header: 'Assigned To', body: ({ users, id }) => <UserAssignment id={id} preAssignedUsers={users} users={usersList ?? []} addCaseSuiteCallback={props.addCaseSuiteCallback} /> },
    ...(usingIn === 'execute'
      ? [
          {
            field: 'added_suites.status.name',
            header: 'Status',
            body: ({ status }) => (
              <span class='cursor-pointer' style={{ marginInline: '3px' }}>
                <Badge className='px-2 py-0' value={status?.name} severity={getBadgeType(status?.name)} />
              </span>
            ),
          },
        ]
      : []),
    {
      header: () => <div className='text-center'>Action</div>,
      alignHeader: 'center',
      style: { padding: '0.3rem 3px' },
      body: ({ id, status, suite }) => {
        const isNotStarted = ['Not Started'].includes(status.name);
        const { isLoading } = mutation;
        return (
          <div className='flex justify-content-center'>
            {usingIn !== 'execute' ? (
              <PermissionButtonGroup
                component={Components.TEST_PLAN}
                actions={[
                  {
                    action: 'delete',
                    onClick: () => {
                      singleDeleteItemId.current = id;
                      setShowDeleteAlert(true);
                    },
                  },
                ]}
              />
            ) : (
              !isNotStarted && (
                <PermissionButtonGroup
                  component={Components.TEST_PLAN}
                  actions={[
                    {
                      tooltip: 'Reset Status to Not Started',
                      tooltipOptions: { position: 'bottom' },
                      action: 'view',
                      icon: !isLoading ? <RefreshIcon /> : <i></i>,
                      disabled: isLoading,
                      loading: isLoading,
                      loadingIcon: <RefreshIcon className='p-icon-spin text-red-500' />,
                      className: 'position-relative text-red-500 ',
                      onClick: () => {
                        mutation.mutate(suite);
                      },
                    },
                  ]}
                />
              )
            )}
          </div>
        );
      },
    },
  ];
  const rowExpansionTemplate = data => {
    console.log(
      added_suites.filter(suite => suite?.parent_id === data.id),
      data,
      '__________________filter_____________________',
    );
    const _data = added_suites.filter(suite => suite?.parent_id === data.id);
    const _columns = [
      ...columns.slice(0, 3),
      {
        field: 'suite.configuration',
        header: 'Configuration',
        style: { padding: 1, width: '150px' },
        body: ({ suite: { configuration } }) => (configuration ? <VariablesValuesList optionLabel={'variable.name'} values={configuration?.configuration_variables ?? []} text={configuration?.name} /> : ''),
      },
      ...columns.slice(3),
    ];
    !_data.length > 0 && setExpandedRows(null);
    return _data?.length > 0 ? (
      <Card
        title={<div className='text-lg p-2'>Name : {_data.at(0)?.suite?.title}</div>}
        pt={{
          body: { className: 'p-0' },
          content: { className: 'pt-0' },
          title: { className: 'mb-0' },
          root: { className: 'shadow-none' },
        }}
      >
        <DataTable value={_data} scrollable scrollHeight='300px'>
          {_columns.map((col, i) => (col.header !== 'Name' ? <Column key={col.field} columnKey={col.field} {...col} header={i === 0 ? '' : col.header} /> : null))}
        </DataTable>
      </Card>
    ) : null;
  };
  const allowExpansion = rowData => {
    return added_suites.some(item => item.parent_id === rowData.id);
  };
  return (
    <div className='p-datatable p-component p-datatable-responsive-scroll datatable-responsive' data-scrollselectors='.p-datatable-wrapper' pr_id_5=''>
      <Toast ref={toast} />
      <AlertDialog message={'Are you sure you want to delete this test plan item?'} show={showDeleteAlert} onYes={onDeleteTestPlanItems} onNo={onDeleteAlertHide} onHide={onDeleteAlertHide} />
      <DataTable
        value={added_suites.filter(val => val.parent_id === null)}
        reorderableRows
        selection={selectedRows}
        scrollable
        expandedRows={expandedRows}
        onRowToggle={e => setExpandedRows(e.data)}
        loading={isLoading || isRefetching}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowReorder={(e, index) => onStorted(e, index)}
        tableStyle={{ minWidth: '100%' }}
      >
        <Column rowReorder style={{ width: '1rem', cursor: 'default ', overflow: 'hidden', padding: 1 }} />
        <Column expander={allowExpansion} style={{ width: '2rem' }} />
        {columns.map((col, i) => (
          <Column key={col.field} columnKey={col.field} {...col} />
        ))}
      </DataTable>
    </div>
  );
});

function UserAssignment({ id, preAssignedUsers, users, addCaseSuiteCallback }) {
  const [showUserSelection, setShowUserSelection] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState(preAssignedUsers.map(users => users.id));
  const [isLoading, setIsLoading] = useState(false);
  const onHideSelection = () => {
    setShowUserSelection(false);
    const controller = new AbortController();
    setIsLoading(true);
    testPlanService
      .updateUserAgainstTestplanitemsSuite(controller, id, assignedUsers)
      .then(() => {
        addCaseSuiteCallback();
      })
      .catch(error => {})
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      });
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (showUserSelection) {
    return (
      <div>
        <MultiSelect
          placeholder='Assign Users'
          value={assignedUsers}
          options={users}
          optionValue='id'
          optionLabel='name'
          maxSelectedLabels={3}
          display='chip'
          onHide={onHideSelection}
          onChange={e => {
            if (e.value.length >= 1) {
              setAssignedUsers(e.value);
            }
          }}
        />
      </div>
    );
  }
  return (
    <span className='cursor-pointer' style={{ marginInline: 3 }} onClick={() => setShowUserSelection(true)}>
      {preAssignedUsers.map(user => (
        <Badge key={user.name} className=' py-0 px-2 ' value={user.name}></Badge>
      ))}
    </span>
  );
}
